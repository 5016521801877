import {
  Form,
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikValues,
} from "formik";
import React, { useState } from "react";
import FormNavigation from "./FormNavigation";
import { GridItem } from "../../../StyledComponents/Index";
import { FormHeadingTypography } from "../../../Cores/FormCores/style";
import Stepper from "../../../Stepper";
import backButton from "../../../../assets/svg/Signup-back-button.svg";
import Grid from "@mui/material/Grid";
import { StepperProvider } from "../../../../context/StepperContext";

interface Props extends FormikConfig<FormikValues> {
  children: React.ReactNode;
  onSubmit: (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => void | Promise<any>;
}

const MultiStepForm = ({
  children,
  initialValues,
  onSubmit,
  ...props
}: Props) => {
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children) as React.ReactElement[];

  const [snapshot, setSnapshot] = useState(initialValues);
  const [serverErrors, setServerErrors] = useState<Record<string, string>>({}); // Store backend validation errors here

  const step = steps[stepNumber];
  const totalsteps = steps.length;
  const isLastStep = stepNumber === totalsteps - 1;

  const next = (values: FormikValues) => {
    setSnapshot(values);
    setStepNumber(stepNumber + 1);
  };

  const previous = (values: FormikValues) => {
    setSnapshot(values);
    setStepNumber(stepNumber - 1);
  };

  const handleSubmit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    try {
      if (step.props.onSubmit) {
        const shouldAdvance = await step.props.onSubmit(values);
        if (!shouldAdvance) {
          return;
        }
        next(values);
      }

      if (isLastStep) {
        await onSubmit(values, actions);
      } else {
        actions.setTouched({});
        next(values);
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.errors) {
        const backendErrors = error.response.data.errors;
        actions.setErrors(backendErrors);
        // actions.setSubmitting(false);
      }
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
    >
      {(formik) => (
        <Form>
          <Grid
            container
            alignItems="center"
            sx={{ marginBottom: "20px", position: "relative" }}
          >
            <GridItem xs={12} md={12}>
              <FormHeadingTypography>Sign up</FormHeadingTypography>
              {stepNumber > 0 && (
                <img
                  src={backButton}
                  onClick={() => previous(formik.values)}
                  height={"44px"}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    left: "0px",
                    top: "-68px",
                  }}
                />
              )}
            </GridItem>
          </Grid>
          {/* <Stepper totalSteps={totalsteps+1} currentStep={stepNumber+1} gap={true} /> */}

          {step}
          <FormNavigation
            isFirststep={stepNumber === 0}
            isLastStep={isLastStep}
            hasPervious={stepNumber > 0}
            isValid={formik.isValid}
            onBackClick={() => previous(formik.values)}
            isSubmitting={formik.isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default MultiStepForm;

export const FormStep = ({ stepName = "", children }: any) => children;
