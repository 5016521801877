import {  FieldConfig, FieldHookConfig,  useField } from "formik";
import React, { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { OffVisibility, OnVisibility } from "../../../Cores/FormCores/style";
import { TextField, TextField2 } from "../style";
import { MuiTelInput } from "mui-tel-input";

interface Props extends FieldConfig {
  label?: string;
  type?: React.HTMLInputTypeAttribute;
  autoComplete?: React.HTMLInputAutoCompleteAttribute;
  disabled?: boolean | undefined;
  onFocus?: (React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined);
  error?: boolean | undefined;
  error2?: string;};

interface PhoneProps {
  label?: string;
  autoComplete?: React.HTMLInputAutoCompleteAttribute;
  disabled?: boolean | undefined;
  error2?: string;
  onFocus?: (React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined);

}
export const InputField = ( {label, type, autoComplete, disabled, onFocus,error2, ...props}: Props & FieldHookConfig<{}>) => {
  const [field, meta, helpers] = useField(props);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <TextField
      fullWidth
      {...field}
      type={showPassword && type === "password" ? "text" : type}
      autoComplete={autoComplete}
      disabled={disabled}
      onFocus={onFocus}
      InputProps={{
        endAdornment: type === "password" && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <OffVisibility /> : <OnVisibility/>}
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={(e) => {
        field.onChange(e);
      }}
      onBlur={(e) => {
        field.onBlur(e);
      }}
      error={(meta.touched && Boolean(meta.error)) || Boolean(error2)}
      helperText={meta.touched && meta.error || error2}
      sx={{ input:{ fontFamily:'Outfit'}
    }}
    />
);
};

export const InputField2 = ( {label, type, autoComplete, disabled,onFocus, error, ...props}: Props & FieldHookConfig<{}>) => {
  const [field, meta, helpers] = useField(props);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <TextField2
      fullWidth
      {...field}
      type={showPassword && type === "password" ? "text" : type}
      autoComplete={autoComplete}
      onFocus={onFocus}
      sx={{
        input: { padding: "11.38px", fontSize: "14px", fontWeight:'500', fontFamily:'Outfit'},
      }}
      InputProps={{
        readOnly:disabled,
        endAdornment: type === "password" && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <OffVisibility /> : <OnVisibility/>}
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={(e) => {
        field.onChange(e);
      }}
      onBlur={(e) => {
        field.onBlur(e);
      }}
      error={(meta.touched && Boolean(meta.error)) || Boolean(error)}
      helperText={meta.touched && meta.error || error}
    />
);
};



export const PhoneField: React.FC<PhoneProps & FieldHookConfig<string>> = ({
  label,
  autoComplete,
  disabled,
  error2,
  onFocus,
  ...props
}) => {
  const [field, meta, helpers] = useField<string>(props);

  return (
    <MuiTelInput
      defaultCountry='LK'
      forceCallingCode
      onFocus={onFocus}
      fullWidth
      {...field}
      autoComplete={autoComplete}
      value={field.value}
      InputProps={{
        readOnly: disabled,
      }}
      onChange={(value) => {
        helpers.setValue(value); // Use helpers.setValue to update the Formik state
      }}
      onBlur={() => {
        helpers.setTouched(true); // Use helpers.setTouched to set the touched state
      }}
      // error={meta.touched && Boolean(meta.error)}
      // helperText={meta.touched && meta.error}
      error={(meta.touched && Boolean(meta.error)) || Boolean(error2)}
      helperText={meta.touched && meta.error || error2}
      sx={{
        "& .MuiInputBase-root": {
          "& fieldset": {
            border: "1px solid rgba(217, 241, 255, 1)",
            borderRadius: "12px",
          },
        },
        "& .MuiTypography-root":{
          color:'#01A4FF',
          borderColor:'#1E1E1E'
        },
        "& .MuiTelInput-FlagImg":{
          width:'25px'
        },
        backgroundColor: 'rgba(252, 254, 255, 1)',
        input:{ fontFamily:'Outfit'}
      }}
    />
  );
};


export const PhoneField2: React.FC<PhoneProps & FieldHookConfig<string>> = ({
  label,
  autoComplete,
  disabled,
  error2,
  onFocus,
  ...props
}) => {
  const [field, meta, helpers] = useField<string>(props);

  return (
    <MuiTelInput
      defaultCountry='LK'
      forceCallingCode
      onFocus={onFocus}
      fullWidth
      {...field}
      autoComplete={autoComplete}
      value={field.value}
      InputProps={{
        readOnly: disabled,
      }}
      onChange={(value) => {
        helpers.setValue(value); // Use helpers.setValue to update the Formik state
      }}
      onBlur={() => {
        helpers.setTouched(true); // Use helpers.setTouched to set the touched state
      }}
      error={(meta.touched && Boolean(meta.error)) || Boolean(error2)}
      helperText={meta.touched && meta.error || error2}
      sx={{
        "& .MuiInputBase-root": {
          "& fieldset": {
            border: '0.5px solid #717171',
            borderRadius: "8px",
          },
        },
        "& .MuiTypography-root":{
          color:'#01A4FF',
          borderColor:'#1E1E1E'
        },
        "& .MuiTelInput-FlagImg":{
          width:'25px'
        },   
        background: '#FCFEFF',
        input:{  padding: "11.38px", fontSize: "14px", fontWeight:'500', fontFamily:'Outfit'}
      }}
    />
  );
};

// export const countryField: React.FC<PhoneProps & FieldHookConfig<string>> = ({
//   label,
//   autoComplete,
//   disabled,
//   ...props
// }) => {
//   const [field, meta, helpers] = useField<string>(props);

//   return (
//     <MuiTelInput
//       defaultCountry='US'
//       forceCallingCode
//       fullWidth
//       {...field}
//       autoComplete={autoComplete}
//       value={field.value}
//       InputProps={{
//         readOnly: disabled,
//       }}
//       onChange={(value) => {
//         helpers.setValue(value); // Use helpers.setValue to update the Formik state
//       }}
//       onBlur={() => {
//         helpers.setTouched(true); // Use helpers.setTouched to set the touched state
//       }}
//       error={meta.touched && Boolean(meta.error)}
//       helperText={meta.touched && meta.error}
//       sx={{
//         "& .MuiInputBase-root": {
//           "& fieldset": {
//             border: "1px solid rgba(217, 241, 255, 1)",
//             borderRadius: "12px",
//           },
//         },     
//         backgroundColor: 'rgba(252, 254, 255, 1)',
//         input:{ fontFamily:'Outfit'}
//       }}
//     />
//   );
// };

// export const CountryField2: React.FC<PhoneProps & FieldHookConfig<string>> = ({
//   label,
//   autoComplete,
//   disabled,
//   ...props
// }) => {
//   const [field, meta, helpers] = useField<string>(props);

//   return (
//     <MuiTelInput
//       defaultCountry='US'
//       forceCallingCode
//       fullWidth
//       {...field}
//       autoComplete={autoComplete}
//       value={field.value}
//       InputProps={{
//         readOnly: disabled,
//       }}
//       onChange={(value) => {
//         helpers.setValue(value); // Use helpers.setValue to update the Formik state
//       }}
//       onBlur={() => {
//         helpers.setTouched(true); // Use helpers.setTouched to set the touched state
//       }}
//       error={meta.touched && Boolean(meta.error)}
//       helperText={meta.touched && meta.error}
//       sx={{
//         "& .MuiInputBase-root": {
//           "& fieldset": {
//             border: '0.5px solid #717171',
//             borderRadius: "8px",
//           },
//         },     
//         background: '#FCFEFF',
//         input:{  padding: "11.38px", fontSize: "14px", fontWeight:'500', fontFamily:'Outfit'}
//       }}
//     />
//   );
// };







// export default InputField;

// import { TextField, TextFieldProps } from "@mui/material";
// import {  FieldConfig, FormikProps, FieldHookConfig,  useField } from "formik";
// import React from "react";


// const InputField = ( props:  FieldHookConfig<string>) => {
//   const [field, meta, helpers] = useField(props);

//   return (
//     <TextField
//       fullWidth
//       {...field}
//       onChange={(e) => {
//         field.onChange(e);
//       }}
//       onBlur={(e) => {
//         field.onBlur(e);
//       }}
//       error={meta.touched && Boolean(meta.error)}
//       helperText={meta.touched && meta.error}
//     />
// );
// };

// export default InputField;

