import { ModalProps } from "@mui/material";
import { FC } from "react";
import { ReactComponent as BinIcon } from "../../assets/svg/delete-icon.svg";
import {
  CancelButton,
  Container,
  DeleteButton,
  FlexRow,
  Heading,
  StyledModal,
  SubHeading,
} from "./style";
interface ConfirmationPopupProps {
  deleteButtonClick: () => void;
  cancelButtonClick: () => void;
  descriptionBody: string | undefined;
}
const ConfirmationPopup: FC<
  ConfirmationPopupProps & Omit<ModalProps, "children">
> = ({ deleteButtonClick, cancelButtonClick, descriptionBody, ...props }) => {
  return (
    <StyledModal {...props}>
      <Container>
        <BinIcon />
        <Heading>Are you sure you want to delete?</Heading>
        <FlexRow>
          <SubHeading>You will not able to recover the </SubHeading>
          <SubHeading color="#000">{descriptionBody}</SubHeading>
        </FlexRow>
        <DeleteButton onClick={deleteButtonClick}>Yes Delete</DeleteButton>
        <CancelButton onClick={cancelButtonClick}>Cancel</CancelButton>
      </Container>
    </StyledModal>
  );
};
export default ConfirmationPopup;