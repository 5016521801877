export function maskEmail1(email: string): string {
    const [localPart, domain] = email.split('@');
    const maskedLocalPart = localPart.substring(0, 1) + '*****' + localPart.substring(localPart.length - 2);
    return `${maskedLocalPart}@${domain}`;
  }

export function maskEmail(email: string): string {
    const [localPart, domain] = email.split('@');
    const maskedLocalPart = '*'.repeat(localPart.length);
    return `${maskedLocalPart}@${domain}`;
  }
  