import { Typography, TextField as Field } from "@mui/material";
import styled from '@emotion/styled'; 
import { GridContainer } from "../../StyledComponents/Index";
import { MuiTelInput } from "mui-tel-input";


export const FormSubHeadingTypography = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "600",
  lineHeight: "25.2px",
  textAlign: "left",
}));

export const AgreementTermTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "300",
  lineHeight: "15.12px",
  textAlign: "center",
  color: "#717171",
  fontFamily:'Outfit'
}));

export const LogoTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "15.12px",
  textAlign: "center",
  color: "#717171",
  fontFamily:'Outfit'
}));

export const LogoFileFormat = styled(Typography)(({ theme }) => ({
  fontSize: "10px",
  fontWeight: "300",
  lineHeight: "12.6px",
  textAlign: "center",
  color: "#717171",
  fontFamily:'Outfit'
}));


export const TextField = styled(Field)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid rgba(217, 241, 255, 1)",
      borderRadius: "12px",
    },
  },
  backgroundColor: 'rgba(252, 254, 255, 1)',
  marginBottom: "16px",
}));

export const LogoGrid = styled(GridContainer)(({ theme }) => ({
  marginBottom: "5px",
}));

export const TextField2 = styled(Field)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: '0.5px solid #717171',
      borderRadius: "8px",
    },
  },
  background: '#FCFEFF',
}));

export const StyledPhoneField = styled(MuiTelInput)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: '0.5px solid #717171',
      borderRadius: "8px",
    },
  },
  background: '#FCFEFF',
}));