import { Formik, FormikValues } from "formik";
import { GridContainer, GridItem } from "../../../StyledComponents/Index";
import { FormTextTypography, StyledButton } from "../../../Cores/FormCores/style";
import { AgreementTermTypography } from ".././style";
import { Link } from "../../../Cores/Link";
import { apiUrls } from "../../../../Utilities/properties";
import { useFormikContext } from 'formik';


interface Props {
  hasPervious?: boolean;
  onBackClick: (values: FormikValues) => void;
  isLastStep: boolean;
  isFirststep: boolean;
  isValid: boolean;
  isSubmitting: boolean;
}

const FormNavigation = (props: Props) => {
  const { isSubmitting, errors } = useFormikContext();

  return (
    <GridContainer>
      <GridItem md={12}>
        {/* <StyledButton type="submit" disabled={!props.isValid || props.isSubmitting}>{props.isLastStep ? "Continue" : "Next"}</StyledButton> */}
        <StyledButton 
          type="submit" 
          disabled={!props.isValid || props.isSubmitting}
        >
          {props.isLastStep ? "Continue" : "Next"}
        </StyledButton>
      </GridItem>

      {props.isFirststep && (
        <GridContainer sx={{justifyContent:'center'}}>
          <GridItem md={9}>
            <AgreementTermTypography>
              By continuing you agree to{" "}
              <span className="agreementTypography">Terms of Service</span> and{" "}
              <span className="agreementTypography">Privacy Policy</span>.
            </AgreementTermTypography>
          </GridItem>
          <GridItem md={12}>
            <FormTextTypography>
              Already have an account?{" "}
              <Link href={apiUrls.LOG_IN}>Sign in</Link>
            </FormTextTypography>
          </GridItem>
        </GridContainer>
      )}
    </GridContainer>
  );
};

export default FormNavigation;
