import { LinkProps, Link as MuiLink } from "@mui/material";

export const Link = ({ sx, ...props }: LinkProps) => {
  return (
    <MuiLink
      variant="body2"
      sx={{ color: "#1E1E1E", textDecorationColor: "#1E1E1E" }}
      {...props}
    />
  );
};
