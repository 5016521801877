import React from 'react';
import robotAsk from './robot-ask.svg';
import robotCode from './robot-code.svg';
import logo from './logo.svg';
import '../../css/style.css';
import defaultLogo from '../svg/default-logo-image.svg';
import successIcon from '../svg/Success_icon.svg';
import settingIcon from '../svg/setting_icon.svg';
import logoutIcon from '../svg/logout_icon.svg';
import uploadIcon from '../svg/cloud-upload-icon.svg';
import pdfIcon from '../svg/pdf-icon.svg';


interface RobotImageProps {
    verifiedState: boolean;
}
interface ImgProps{
  height: string | number | undefined;
  width: string | number | undefined;
}

export const RobotImage: React.FC<RobotImageProps> = ({ verifiedState }) => {
  return (
    <>
      {verifiedState ? (
        <img src={robotCode} alt="Layout-Robot-code-img" className="layout-robot-code"/>
      ) : (
        <img src={robotAsk} alt="Layout-Robot-ask-img" className="layout-robot-ask" />
      )}
    </>
  );
};


export const LogoImage: React.FC = () => {
    return (
        <img src={logo} alt="Layout-Logo" className="layout-logo"/>
    );
  };

export const SuccessIcon: React.FC = () => {
  return (
      <img src={successIcon} alt="SuccessIcon" className="successIcon"/>
  );
};

export const SettingIcon: React.FC = () => {
  return (
      <img src={settingIcon} alt="settingIcon" className="settingIcon"/>
  );
};

export const LogoutIcon: React.FC = () => {
  return (
      <img src={logoutIcon} alt="logoutIcon" className="logoutIcon"/>
  );
};

export const UploadIcon: React.FC = () => {
  return (
      <img src={uploadIcon} alt="uploadIcon" className="uploadIcon"/>
  );
};

export const PdfIcon: React.FC<ImgProps>= ({height,width}) => {
  return (
      <img src={pdfIcon} alt="PdfIcon" className="PdfIcon" height={height} width={width}/>
  );
};
// export const DefaultLogo: React.FC = () => {
// return (
//     <img src={defaultLogo} alt="default-logo-image" height="32px"/>
// );
// };

