import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "../../Cores/Link/index";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert } from "@mui/material";
import { apiUrls, help } from "../../../Utilities/properties";
import { ResendOtp, VerfityOtp } from "../../../services/auth.services";
import { GridContainer, GridItem } from "../../StyledComponents/Index";
import {
  StyledField,
  StyledButton,
  ErrorGrid,
} from "../../Cores/FormCores/style";
import {   FormHeadingTypography, OtpFormTypography, OtpSuceessTextFormat, OtpTextFormat, StyleButton_2 } from "./style";
import Stepper from "../../Stepper";
import { maskEmail1 } from "../../../Utilities/validation";
import { SuccessIcon } from "../../../assets/svg";
import Grid from "@mui/material/Grid";

// const ValidationErrorHandler = ({
//   setErrorMsg,
// }: {
//   setErrorMsg: React.Dispatch<React.SetStateAction<string>>;
// }) => {
//   const formik = useFormikContext<any>();

//   // useEffect(() => {
//   //   if (formik.touched.email && formik.errors.email) {
//   //     const errorMessage = Array.isArray(formik.errors.email)
//   //       ? formik.errors.email[0]
//   //       : formik.errors.email;
//   //     setErrorMsg(errorMessage as string);
//   //   } else if (formik.touched.password && formik.errors.password) {
//   //     const errorMessage = Array.isArray(formik.errors.password)
//   //       ? formik.errors.password[0]
//   //       : formik.errors.password;
//   //     setErrorMsg(errorMessage as string);
//   //   } else {
//   //     setErrorMsg("");
//   //   }
//   // }, [formik.touched, formik.errors]);

//   return null;
// };
export default function OtpVerificationForm() {
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const [timer, setTimer] = useState<number>(1);
  const email_address = localStorage.getItem("emailAddress");
  const [otpGeneratedAt, setOtpGeneratedAt] = useState<string>(
    localStorage.getItem("otp_generated_at") || ""
  );
  const expiration_sec = parseInt(
    localStorage.getItem("expiration_time") || "600"
  );
  const [successState, setSuccesState] = useState<boolean>(false);
  const {state}=useLocation();
  useEffect(()=>{
if(!state?.fromRegister)
  {
    navigate(apiUrls.REGISTER);
  }
  
  },[state?.fromRegister]);

  useEffect(() => {
    if (otpGeneratedAt) {
      const otpGeneratedTime = new Date(otpGeneratedAt).getTime();
      const expirationTime = otpGeneratedTime + expiration_sec * 1000;
      const currentTime = new Date().getTime();
      const remainingTime = Math.max((expirationTime - currentTime) / 1000, 0);
      setTimer(Math.floor(remainingTime));
    }
  }, [otpGeneratedAt]);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(
        () => setTimer((prevTimer) => prevTimer - 1),
        1000
      );
      return () => clearInterval(countdown);
    }
  }, [timer]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const validationSchema = Yup.object({
    otp: Yup.string().required("Otp " + help.VALUE_REQUIRED),
  });

  const onSubmit = async (values: any) => {
    const { otp } = values;

    try {
      const response = await VerfityOtp({
        email_address: email_address,
        otp: otp,
      });
      if (response.status === 200) {
        setSuccesState(true);
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errMsg = error.response.data.error;
        setErrorMsg(errMsg);
      } else {
        setErrorMsg("Failed to Verify user. Please try again later.");
      }
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await ResendOtp({
        email_address: email_address,
      });
      localStorage.setItem("otp_generated_at", response.data.otp_generated_at);
      setOtpGeneratedAt(response.data.otp_generated_at);
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errMsg = error.response.data.error;
        setErrorMsg(errMsg);
      } else {
        setErrorMsg("Failed to resend OTP. Please try again.");
      }
    }
  };

  return (
    <GridContainer>
      { successState ? (
        <Grid container justifyContent="center" rowSpacing={4}>
          <Grid item sx={{marginTop:'30px'}}>
            <SuccessIcon />
          </Grid>
          <Grid item>
            <FormHeadingTypography>You’re all signed up!</FormHeadingTypography>
          </Grid>
          <Grid item xs={12} md={12}>
            <OtpSuceessTextFormat>Your settings have been saved</OtpSuceessTextFormat>
          </Grid>
          <Grid item xs={5} md={5}>
            <StyleButton_2
              type="button"
              onClick={() => navigate(apiUrls.LOG_IN)}
            >
              Continue
            </StyleButton_2>
          </Grid>
        </Grid>
      ) : (
        <Formik
          initialValues={{ otp: "" }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              {/* <ValidationErrorHandler setErrorMsg={setErrorMsg} /> */}
              <GridContainer>
                <GridContainer
                  sx={{
                    alignItems: "center",
                  }}
                >
                  <GridItem xs={10} md={9}>
                    <FormHeadingTypography>
                      OTP Verification
                    </FormHeadingTypography>
                  </GridItem>
                  <GridItem xs={2} md={3}>
                    <GridContainer
                      sx={{ alignItems: "center", justifyContent: "right" }}
                    >
                      <Stepper currentStep={4} totalSteps={4} gap={true} />
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <ErrorGrid md={12} sx={{ height: "50px" }}>
                  {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
                </ErrorGrid>
                <GridItem md={12}>
                  <OtpFormTypography>
                    {" "}
                    Enter OTP code we sent to {maskEmail1(email_address || "")}
                  </OtpFormTypography>
                </GridItem>

                <GridItem md={12}>
                  <OtpTextFormat>
                    Please check your inbox and enter the verification code
                    below to verify your email address. The code will expire in{" "}
                    <span
                      style={{
                        color: timer > 60 ? "#0059AB" : "red",
                        fontWeight: "400",
                      }}
                    >
                      {formatTime(timer)}
                    </span>
                    .
                  </OtpTextFormat>
                </GridItem>

                <GridItem md={12} sx={{ height: "80px" }}>
                  <Field
                    name="otp"
                    type="text"
                    component={StyledField}
                    error={
                      (formik.touched.otp && Boolean(formik.errors.otp)) ||
                      errorMsg
                    }
                    helperText={<ErrorMessage name="otp" />}
                    onFocus={() => setErrorMsg("")}
                  />
                </GridItem>

                <GridItem md={12}>
                  <StyledButton type="submit">Verify</StyledButton>
                </GridItem>

                {timer <= 0 && (
                  <GridItem md={12}>
                    <GridContainer justifyContent="center">
                      <Link
                        onClick={handleResendOtp}
                        style={{ cursor: "pointer" }}
                      >
                        Send again
                      </Link>
                    </GridContainer>
                  </GridItem>
                )}
              </GridContainer>
            </Form>
          )}
        </Formik>
      )
    }
    </GridContainer>
  );
}
