import styled from "@emotion/styled";
import {
    Button,
    ButtonProps,
    Modal,
    ModalProps,
    Paper,
    PaperProps,
    Stack,
    StackProps,
    Typography,
    TypographyProps,
  } from "@mui/material";


export const StyledButton3 = styled(Button)`

height: Hug (40px)px;
padding: 10px 20px 10px 20px;
border-radius: 8px;
font-family: Outfit;
font-size: 16px;
font-weight: 500;
text-align: left;
color: #01A4FF;
background: #FFFFFF;
border: 1px solid #A7A7A74D;
box-shadow: 0px 2px 4px 0px #1E1E1E29;
text-transform: none;

`


export const StyledButton4 = styled(Button)`

height: Hug (40px)px;
padding: 12px 24px 12px 24px;
border-radius: 8px;
font-family: Outfit;
font-size: 16px;
font-weight: 500;
text-align: left;
color: #FFFFFF;
background: linear-gradient(182.29deg, #01A4FF 1.82%, #008CDB 97.99%);
border: 1px solid #A7A7A74D;
box-shadow: 0px 2px 4px 0px #1E1E1E29;
text-transform: none;

`


    // padding: "10px 20px 10px 20px",
    // borderRadius: "8px",
    // height: "40px",
    // background: '#FFFFFF',
    // border: '1px solid #A7A7A74D',
    // boxShadow: '0px 2px 4px 0px #1E1E1E29',
    // textAlign:'center',
    // color: '#01A4FF',
    // textTransform:'none',
    // fontFamily: 'Outfit',
    // fontSize: '16px',
    // fontWeight: '500',


export const CompanyNameText = styled(Typography)`
font-family: Outfit;
font-weight: 600;
text-align: left;
margin: 0px;
color: #1E1E1E;
`;

export const DetailFormText = styled(Typography)`
font-family: Outfit;
font-weight: 400;
text-align: left;
margin: 0px;
padding-bottom:5px;
color: #717171;
`;

export const StyledModal = styled((props: ModalProps) => (
    <Modal sx={{ display: "flex", p: "15px",zIndex:"5000"}} {...props} />
  ))({});
  export const Container = styled((props: PaperProps) => (
    <Paper
      elevation={1}
      sx={{
        border: "none",
        outline: "none",
        maxWidth: "460px",
        width: "100%",
        m: "auto",
        borderRadius: "30px",
        p: { xs: "20px 30px", sm: "20px 55px", md: "20px 85px" },
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        alignItems: "center",
        zIndex:"5000",
      }}
      {...props}
    />
  ))({});
  export const Heading = styled((props: TypographyProps) => (
    <Typography
      fontSize={{ xs: "15px", sm: "18px" }}
      textAlign="center"
      fontWeight={600}
      {...props}
    />
  ))({});
  export const SubHeading = styled((props: TypographyProps) => (
    <Typography
      fontSize="12px"
      fontWeight={700}
      color="#929292"
      whiteSpace="nowrap"
      // textAlign="center"
      {...props}
    />
  ))({});
  export const DeleteButton = styled((props: ButtonProps) => (
    <Button
      color="error"
      variant="contained"
      sx={{
        width: "145px",
        height: "37px",
        fontSize: "12px",
        fontWeight: 600,
        borderRadius: "30px",
        backgroundColor: "#FE6666",
        "&:hover": {
          backgroundColor: "#FE6666",
          boxShadow: "none",
        },
      }}
      {...props}
    />
  ))({});
  export const CancelButton = styled((props: ButtonProps) => (
    <Button
      variant="text"
      sx={{
        fontSize: "14px",
        fontWeight: 600,
        backgroundColor: "transparent",
        color: "#024B8F",
        "&:hover": {
          color: "#024B8F",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      {...props}
    />
  ))({});
  export const FlexRow = styled((props: StackProps) => (
    <Stack flexDirection='row' gap='3px' justifyContent='center' alignItems='center'
      {...props}
    />
  ))({});