import { apiUrls } from '../Utilities/properties';
import axios from 'axios';
import axiosInstance from  '../Utilities/axiosInstance';

const AUTH_API_URL = apiUrls.BASEURL + apiUrls.AUTH;

const logIn = (credentials: any) => {
  return axios.post(AUTH_API_URL + apiUrls.LOG_IN, credentials);
};

const Register = (credentials: any) => {
return axiosInstance.post(apiUrls.AUTH + apiUrls.REGISTER, credentials);
};

const UserCheck = (credentials: any) => {
  return axios.post(AUTH_API_URL + apiUrls.USER_CHECK, credentials);
};

const UserCheck2 = (credentials: any) => {
  return axios.post(AUTH_API_URL + apiUrls.USER_CHECK2, credentials);
};

const VerfityOtp = (credentials: any) => {
  return axios.post(AUTH_API_URL + apiUrls.VERIFY_EMAIL, credentials);
};

const ResendOtp = (credentials: any) => {
  return axios.post(AUTH_API_URL + apiUrls.RESEND_OTP, credentials);
};

const UserDetails = (credentials: any) => {
  return axiosInstance.get(apiUrls.USER + apiUrls.USER_DETAILS, credentials);
};

const fetchFiles = (credentials: any) => {
  return axiosInstance.get(apiUrls.USER + apiUrls.FILE, credentials);
};

const uploadFiles = (credentials: any) => {
  return axiosInstance.post(apiUrls.USER + apiUrls.UPLOAD, credentials);
};

const addFileEmbedding = (fileId:number, credentials: any) => {
  return axiosInstance.post(apiUrls.USER + apiUrls.ADD_EMBED+`/${fileId}`, credentials);
};

const updateFileEmbedding = (credentials: any) => {
  return axiosInstance.post(apiUrls.USER + apiUrls.UPDATE_EMBED, credentials);
};

const deleteFiles = (fileId:number, credentials: any) => {
  return axiosInstance.delete(apiUrls.USER + apiUrls.DELETE+`/${fileId}`, credentials);
};

const UpdateProfile = (credentials: any) => {
  return axiosInstance.put(apiUrls.USER + apiUrls.UPDATE, credentials);
};

  export {
    logIn,
    Register,
    VerfityOtp,
    ResendOtp,
    UserDetails,
    fetchFiles,
    uploadFiles,
    deleteFiles,
    UpdateProfile,
    UserCheck,
    UserCheck2,
    updateFileEmbedding,
    addFileEmbedding
}