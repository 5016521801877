import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Box,
  Button,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  TextField,
} from "@mui/material";
import {
  CloudUpload,
  Delete,
  Close,
  Settings,
  ExitToApp,
} from "@mui/icons-material";
import { fontWeight, styled } from "@mui/system";
import logoImage from "../../assets/svg/logo.svg";
import { LogoutIcon, SettingIcon, UploadIcon } from "../../assets/svg";
import theme from "../../components/theme";
import { StyleButton_2 } from "../../components/Forms/OTP/style";
import { StyledButton } from "../../components/Cores/FormCores/style";
import { UserDetails } from "../../services/auth.services";
import { Navigate, useNavigate } from "react-router-dom";
import { apiUrls, message, success } from "../../Utilities/properties";
import { useAvatarContext } from "../../context/AvatarContext";
import { enqueueSnackbar } from "notistack";

const UploadBox = styled(Grid)({
  textAlign: "center",
  marginLeft: "30px",
  backgroundColor: "#fafafa",
  border: "2px dashed rgba(1, 164, 255, 1)",
  borderRadius: "12px",
  height: "590px",
  width: "88%",
});

const UploadedFilesBox = styled(Box)({
  padding: "20px",
  backgroundColor: "#ffffff",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const ProfileBox = styled(Box)({
  minWidth:'120px',
  display: "flex",
  alignItems: "center",
  padding: "8px",
  borderRadius: "8px",
  cursor: "pointer",
  border: "0.5px solid #01A4FF",
  gap: "17px",
});

const MenuBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "7px 8px",
  borderRadius: "5px",
  backgroundColor: "#ffffff",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  width: "170px",
});

const DropDownText = styled(Typography)({
  fontWeight: "300",
  fontFamily: "Outfit",
  fontSize: "12px",
  paddingLeft: "7px",
});

// const BodyContainer = styled(Container)({
//   height: 'calc(100vh - 64px)', // Adjust based on AppBar height
//   overflow: 'auto',
// });
interface PrivateLayoutProps {
  children: React.ReactNode;
}
interface Admin {
  first_name: string;
  email_address: string;
}
interface Company {
  company_name: string;
  logo: string;
}
const PrivateLayout: React.FC<PrivateLayoutProps>= ({children, ...props}) => {

  const {userAvatar,setUserAvatar, userHeaderName, setUserHeaderName, userCompanyName, setUserCompanyName} = useAvatarContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [admin, setAdmin] = useState<Admin>({ first_name: '', email_address: '' });
  const [company, setCompany] = useState<Company>({company_name:'', logo:''});
  const token = localStorage.getItem('token');
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if(!token){
      navigate(apiUrls.LOG_IN);
    }
    const fetchAdmin = async () => {
      console.log(token);
      try {
      const response = await UserDetails( {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      setAdmin(response.data.user);
      setUserAvatar(response.data.company.logo);
      setUserHeaderName(response.data.user.first_name);
      setUserCompanyName(response.data.company.company_name);
      setCompany(response.data.company);
      localStorage.setItem("company_code", response.data.company.company_code);
      // console.log(response.data.company);
      setPreview(response.data.company.logo);
    } catch (error: any) {
          if (error.response && error.response.data) {
            const errMsg = error.response.data.error;
            console.log("error:",errMsg);
          }
        }
    };

    fetchAdmin();
  }, [userAvatar,userHeaderName]);

  console.log(userAvatar);
  console.log(userHeaderName);

  
  
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid>
      <AppBar
        position="fixed"
        style={{
          background:
            "radial-gradient(132.65% 173.85% at 70.04% 95.21%, #110D28 26.5%, #A2123D 100%)",
        }}
      >
        <Toolbar>
          <Box style={{ flexGrow: 1 }}>
            <img src={logoImage} height={"20px"}  onClick={() => window.location.href = 'https://www.techorin.lk/'} style={{cursor:'pointer'}}/>
          </Box>
          <ProfileBox onClick={handleProfileMenuOpen}>
            <Avatar style={{ height: "34px", width: "34px" }} src={
                              // preview ? preview.toString() : "/broken-image.jpg"
                              `${apiUrls.BASEURL}/uploads/${userAvatar}`
                            } />
            <Typography style={{ fontWeight: "500", fontFamily: "Outfit" }}>
              {userHeaderName}
            </Typography>
          </ProfileBox>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleProfileMenuClose}
            PaperProps={{
              style: {
                background:
                  "linear-gradient(90deg, #FFF2FD 0%, #F3F0FF 51.5%, #E8F9FF 100%)",
                border: "1px solid rgba(0, 136, 211, 0.25)",
                marginLeft: "-8px",
                marginTop: "3px",
              },
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              rowGap={1}
            >
              <Avatar style={{ height: "45px", width: "45px" }} src={`${apiUrls.BASEURL}/uploads/${userAvatar}`}/>
              <Typography style={{ fontWeight: "500", fontFamily: "Outfit" }}>
              {userHeaderName}
              </Typography>
            </Box>
            <Box sx={{ marginTop: "16px" }}>
              <MenuItem sx={{ padding: "0px", margin: "8px" }}>
                <MenuBox onClick={()=>navigate(apiUrls.PROFILE)}>
                  <SettingIcon />
                  <DropDownText>Profile settings</DropDownText>
                </MenuBox>
              </MenuItem>
              <MenuItem sx={{ padding: "0px", margin: "8px" }}>
                <MenuBox  onClick={()=>{localStorage.removeItem("token");
                  navigate(apiUrls.LOG_IN);
                  enqueueSnackbar(message.LOGOUT_SUCCESS, {variant:"success"});
                }}>
                  <LogoutIcon />
                  <DropDownText>Log out</DropDownText>
                </MenuBox>
              </MenuItem>
            </Box>
          </Menu>
        </Toolbar>
      </AppBar>
      {children}
    </Grid>
  );
};

export default PrivateLayout;
