import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Box,
  Button,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  TextField,
  LinearProgress,
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  Collapse,
  Alert,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import {
  linearProgressClasses,
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import {
  CloudUpload,
  Delete,
  Close,
  Settings,
  ExitToApp,
} from "@mui/icons-material";
import { borderRadius, fontWeight, padding, Stack, styled } from "@mui/system";
import logoImage from "../../assets/svg/logo.svg";
import { LogoutIcon, PdfIcon, SettingIcon, UploadIcon } from "../../assets/svg";
import theme from "../../components/theme";
import { StyleButton_2 } from "../../components/Forms/OTP/style";
import {
  StyledButton,
  StyledField,
  StyledFormLabel,
  StyledTextField,
} from "../../components/Cores/FormCores/style";
import PrivateLayout from "../../Layout/PrivateLayout";
import { useNavigate } from "react-router-dom";
import {
  addFileEmbedding,
  deleteFiles,
  updateFileEmbedding,
} from "../../services/auth.services";
import { useDropzone } from "react-dropzone";
import SearchIcon from "@mui/icons-material/Search";
import ConfirmationPopup from "../../Layout/PrivateLayout/ConfirmationPopup";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { apiUrls } from "../../Utilities/properties";
import axiosInstance from "../../Utilities/axiosInstance";
import { Link } from "../../components/Cores/Link";
import chatCopyIcon from "../../assets/svg/Chat-copy-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import axios, { CancelTokenSource } from "axios";
import { log } from "console";

const UploadBox = styled(Grid)({
  textAlign: "center",
  marginLeft: "30px",
  backgroundColor: "#fafafa",
  border: "2px dashed rgba(1, 164, 255, 1)",
  borderRadius: "12px",
  height: "550px",
  width: "88%",
});

const DropDownUploadBox = styled(Grid)({
  textAlign: "center",
  backgroundColor: "#fafafa",
  border: "2px dashed rgba(1, 164, 255, 1)",
  borderRadius: "12px",
  height: "173px",
});

const UploadedFilesBox = styled(Box)({
  padding: "20px",
  backgroundColor: "#ffffff",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const ProfileBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "8px",
  borderRadius: "8px",
  cursor: "pointer",
  border: "0.5px solid #01A4FF",
  gap: "17px",
});

const MenuBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "7px 8px",
  borderRadius: "5px",
  backgroundColor: "#ffffff",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  width: "170px",
});

const DropDownText = styled(Typography)({
  fontWeight: "300",
  fontFamily: "Outfit",
  fontSize: "12px",
  paddingLeft: "7px",
});

const ListItemPdf = styled(Box)({
  fontWeight: "300",
  fontFamily: "Outfit",
  fontSize: "12px",
  height: "48px",
  width: "75%",
  padding: "5px",
  borderRadius: "4px",
  border: "1px solid #D9F1FF",
  background: "white",
});

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: "10px",
        top: "-10px",
        position: "relative",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "right" }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", fontSize: "12px" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <LinearProgress variant="determinate" color="secondary" {...props} />
      </Box>
    </Box>
  );
}

// const BodyContainer = styled(Container)({
//   height: 'calc(100vh - 64px)', // Adjust based on AppBar height
//   overflow: 'auto',
// });

const Home: React.FC = () => {
  // const [files, setFiles] = useState<{ name: string; uploading: boolean }[]>(
  //   []
  // );
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);
  const [files, setFiles] = useState<FileList | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<
    { id: number; name: string; description: string }[]
  >([]);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filename, setFilename] = useState("");
  const [description, setDescription] = useState("");
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [updatingEmbed, setUpdatingEmbed] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [infoFile, setInfoFile] = useState<{
    name: string;
    description: string;
  } | null>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState<number | null>(null);
  const [clicked, setClicked] = useState(false);
  const [openAlertFetch, setOpenAlertFetch] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fileTotalSize, setFileTotalSize] = useState<number | null>(null);

  //fetch userdetails
  useEffect(() => {
    fetchFilesFromServer();
  }, []);

  const fetchFilesFromServer = async () => {
    try {
      const response = await axiosInstance.get(`${apiUrls.USER}/files`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const files = response.data.files;
      setUploadedFiles(files);
      console.log(files);

      // Calculate the total size of all files
      const totalSize = files.reduce(
        (sum: number, file: { size: number }) => sum + file.size,
        0
      );
      console.log("Total size of all files (in bytes):", totalSize);
      setFileTotalSize(totalSize);
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errMsg = error.response.data.error;
        console.log("error:", errMsg);
      }
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      console.log(file);
      console.log(file.type);

      // Validate file type
      if (file.type !== "application/pdf") {
        setErrorMessage("Invalid format: Only PDF files are allowed.");
        return;
      }

      // Validate file size (5MB minimum)
      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage("File size too large: Maximum allowed size is 5MB.");
        return;
      }

      // Validate total file size (50MB minimum)
      if (fileTotalSize) {
        if (file.size + fileTotalSize > 50 * 1024 * 1024) {
          const availableSpace = Math.floor((50 * 1024 * 1024 - fileTotalSize) / 1024 / 1024 * 10) / 10;
          setErrorMessage(
            "The total file size exceeds the 50 MB limit. Available space remaining: " +
            availableSpace + " MB."
          );
          return;
        }
      }
      // If valid, reset error and set the file
      setErrorMessage(null);
      setSelectedFile(file);
      setOpen(true);
    } else {
      setErrorMessage("Invalid file format");
    }
  };

  //file name
  useEffect(() => {
    if (selectedFile) {
      setFilename(selectedFile.name.split(".")[0]);
    }
  }, [selectedFile]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  const handleClose = () => {
    setOpen(false);
    setDescription("");
  };

  // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setFiles(e.target.files);
  // };

  //cancel the upload progress
  const handleCancel = () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Upload canceled by the user.");
      console.log("Cancel token executed");
      setSelectedFile(null);
      setUploading(false);
      setProgress(0);
    } else {
      console.log("No cancel token available");
    }
  };

  //service of upload process
  const handleUpload = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("pdf_file", selectedFile);
    formData.append("file_name", filename);
    formData.append("description", description);

    cancelTokenSource.current = axios.CancelToken.source();

    try {
      handleClose();
      setUploading(true);
      setProgress(0);
      const response = await axiosInstance.post(
        `${apiUrls.USER}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: cancelTokenSource.current.token,
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total || 0;
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / total
            );
            setProgress(percentCompleted);
          },
        }
      );
      fetchFilesFromServer();
      const fileId = response.data.file_id;
      setMessage(response.data.message);
      setUploading(false);
      setSelectedFile(null);
      try {
        setUpdatingEmbed(true);
        setOpenAlertFetch(true);
        const addresponse = await addFileEmbedding(fileId, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(addresponse.data);
        try {
          const updateresponse = await updateFileEmbedding({
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(updateresponse.data);
          setUpdatingEmbed(false);
          setTimeout(() => {
            setOpenAlertFetch(false);
          }, 6000);
        } catch (error: any) {
          if (error.updateresponse && error.updateresponse.error) {
            const errMsg = error.updateresponse.error;
            console.log("Error updating file embedding:", errMsg);
          }
          setUpdatingEmbed(false);
          setMessage("Error updating file embedding");
        }
      } catch (error: any) {
        if (error.addresponse && error.addresponse.error) {
          const errMsg = error.addresponse.error;
          console.log("Error add newfile embedding:", errMsg);
        }
        setUpdatingEmbed(false);
        setMessage("Error add newfile embedding");
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Upload canceled:", error.message);
      } else {
        console.error("Upload error:", error);
      }
    } finally {
      setUploading(false);
      setSelectedFile(null);
      cancelTokenSource.current = null; // Reset cancel token
    }
  };

  // service of file delete
  const handleDeleteConfirmation = (fileId: number) => {
    setSelectedFileId(fileId);
    setDeletePopupOpen(true);
  };

  const handleDelete = async () => {
    if (selectedFileId === null) return;
    try {
      const res = await deleteFiles(selectedFileId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMessage("File successfully deleted!");
      fetchFilesFromServer();
      if (res.status == 200) {
        try {
          setOpenAlertFetch(true);
          setUpdatingEmbed(true);
          const response = await updateFileEmbedding({
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(response.data);
          setUpdatingEmbed(false);
          setTimeout(() => {
            setOpenAlertFetch(false);
          }, 4000);
        } catch (error: any) {
          const errMsg =
            error.response?.data?.error || "An unknown error occurred";
          console.log("Error updating file embedding:", errMsg);
          setUpdatingEmbed(false);
          setTimeout(() => {
            setOpenAlertFetch(false);
          }, 4000);
          setMessage("Error updating file embedding");
        }
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      setMessage("Error deleting file");
    } finally {
      setDeletePopupOpen(false);
    }
  };

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const filteredFiles = uploadedFiles.filter((file) =>
    file.name.toLowerCase().startsWith(searchTerm.toLowerCase())
  );

  const handleFileClick = (file: { name: string; description: string }) => {
    setInfoFile(file);
    setInfoOpen(true);
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
    setInfoFile(null);
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [uploadedFiles]);

  const truncateFilename = (filename: string, maxLength: number) => {
    return filename.length > maxLength
      ? `${filename.substring(0, maxLength)}...`
      : filename;
  };

  // service for file download process
  const handleDownload = async (fileId: number) => {
    try {
      const response = await axiosInstance.get(
        `${apiUrls.USER}/download/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { filename, content } = response.data;
      console.log(response.data);
      const binaryContent = atob(content);
      const arrayBuffer = new ArrayBuffer(binaryContent.length);
      const uintArray = new Uint8Array(arrayBuffer);
  
      for (let i = 0; i < binaryContent.length; i++) {
        uintArray[i] = binaryContent.charCodeAt(i);
      }
  
      const blob = new Blob([arrayBuffer], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
      setMessage("Error downloading file");
    }
  };

  const onCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard:", text);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const company_code = localStorage.getItem("company_code");

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenAlertFetch(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <PrivateLayout>
      <Grid container justifyContent={"center"}>
        <ConfirmationPopup
          open={isDeletePopupOpen}
          deleteButtonClick={handleDelete}
          cancelButtonClick={() => setDeletePopupOpen(false)}
          descriptionBody={"file"}
        />
        <Grid container width="90%">
          <Box
            sx={{
              marginTop: "90px",
              boxShadow: "0px 4px 8px 2px rgba(0, 0, 0, 0.08)",
              borderRadius: "6px",
              minWidth: "100px",
              width: "fit-content",
              padding: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Link
              href={`https://${company_code}.techorin.xyz`}
              style={{
                fontFamily: "Outfit",
                fontSize: "16px",
                fontWeight: "400",
                textAlign: "left",
                color: "#004F7B",
                textDecoration: "underline",
              }}
            >
              {company_code}.techorin.xyz
            </Link>
            <img
              src={chatCopyIcon}
              alt="chatbot"
              style={{
                paddingLeft: "20px",
                cursor: "pointer",
                filter: clicked
                  ? "invert(38%) sepia(82%) saturate(2213%) hue-rotate(221deg) brightness(97%) contrast(97%)"
                  : "none",
              }}
              onClick={() => {
                setClicked(true);
                onCopy(`https://${company_code}.techorin.xyz`);
              }}
            />
          </Box>
        </Grid>
        <Grid
          container
          height="700px"
          width="90%"
          style={{
            marginTop: "35px",
            boxShadow: "0px 4px 8px 2px rgba(0, 0, 0, 0.08)",
            borderRadius: "6px",
          }}
        >
          <Grid item xs={12} sm={12} md={7}>
            <Grid container direction={"column"}>
              <Grid item>
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: "32px",
                    fontWeight: "600",
                    textAlign: "left",
                    color: theme.palette.primary.main,
                    margin: "30px",
                  }}
                >
                  Upload files
                </Typography>
              </Grid>

              <Grid item width={"95%"}>
                <UploadBox
                  container
                  alignContent={"center"}
                  justifyContent={"center"}
                  direction={"column"}
                  rowSpacing={"20px"}
                  {...getRootProps()}
                >
                  <Grid item>
                    <input {...getInputProps()} />
                  </Grid>
                  <Grid item>
                    <UploadIcon />
                  </Grid>
                  <Grid item>
                    <label htmlFor="file-upload">
                      <StyledButton
                        type="button"
                        component="span"
                        variant="contained"
                        style={{
                          width: "128px",
                          backgroundColor: theme.palette.secondary.main,
                        }}
                      >
                        Browse
                      </StyledButton>
                    </label>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="textSecondary">
                      drop a file here
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ marginBottom: "60px" }} variant="body2">
                      <span style={{ color: "red" }}>*</span>File supported .pdf
                    </Typography>
                  </Grid>
                  {errorMessage && (
                    <Grid item>
                      <Typography variant="body2" color="error">
                        {errorMessage}
                      </Typography>
                    </Grid>
                  )}
                </UploadBox>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={5}>
            <Grid
              container
              height="600px"
              width="90%"
              rowGap={"16px"}
              style={{
                marginTop: "60px",
              }}
            >
              <Grid item md={12}>
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    fontWeight: "600",
                    textAlign: "left",
                    color: "#1E1E1E",
                  }}
                >
                  Uploaded Files
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search documents"
                  onChange={(e) => handleSearch(e.target.value)}
                  // InputProps={{style:{border:'1px solid rgba(113, 113, 113, 1)', borderRadius:'12px'}}}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid rgba(113, 113, 113, 1)",
                        borderRadius: "12px",
                        fontFamily: "Outfit",
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon
                        sx={{ height: "16px", width: "16px", padding: "8px" }}
                      />
                    ),
                    sx: { color: "#717171" },
                  }}
                  // style={{border:'1px solid rgba(113, 113, 113, 1)', borderRadius:'12px'}}
                />
              </Grid>

              {/* <Grid item style={{background: '#A7A7A714', padding:'15px', height:'535px'}}>
                <List>
                  {uploadedFiles.map((file, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={file.name} />
                      <ListItemSecondaryAction> */}
              {/* {file.uploading ? (
                          <CircularProgress size={24} />
                        ) : ( */}
              {/* <>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleDelete(file.id)}
                            >
                              <Delete />
                            </IconButton>
                          </> */}
              {/* )} */}
              {/* </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid> */}
              <Grid
                item
                md={12}
                style={{
                  background: "#A7A7A714",
                  padding: "15px",
                  height: "80%",
                  overflowY: "auto",
                  borderRadius: "8px",
                  width: "100%",
                }}
              >
                <List ref={listRef}>
                  {filteredFiles.map((file, index) => (
                    <ListItem sx={{ padding: "4px 8px" }}>
                      <ListItemPdf
                        key={index}
                        onClick={() => handleFileClick(file)}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{ padding: "5px" }}
                      >
                        <Grid container alignItems={"center"}>
                          <PdfIcon height={"24px"} width={"24px"} />
                          <ListItemText
                            sx={{
                              paddingLeft: "10px",
                              "& .MuiTypography-root": { fontSize: "14px" },
                            }}
                            primary={truncateFilename(file.name, 15)}
                          />
                        </Grid>
                        <ListItemSecondaryAction></ListItemSecondaryAction>
                      </ListItemPdf>
                      <Box>
                        <IconButton
                          style={{ marginLeft: "15px" }}
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDownload(file.id)}
                        >
                          <FileDownloadIcon />
                        </IconButton>
                        <IconButton
                          style={{ marginLeft: "15px" }}
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteConfirmation(file.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </ListItem>
                  ))}
                  {
                  uploading && selectedFile && 
                  (
                    <ListItem sx={{ padding: "4px 8px" }}>
                      <ListItemPdf
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{ padding: "5px" }}
                      >
                        <Grid container alignItems={"center"} direction={"row"}>
                        <Grid item>
                          <PdfIcon height={"24px"} width={"24px"} />
                        </Grid>
                          <Grid item alignItems={"center"} sx={{width:{xs:'83%'}}}>
                            <ListItemText
                              sx={{
                                paddingLeft: "10px",
                                top: "5px",
                                position: "relative",
                                "& .MuiTypography-root": { fontSize: "14px" },
                              }}
                              primary={truncateFilename(selectedFile.name, 20)}
                            />
                            <LinearProgressWithLabel
                              sx={{ paddingLeft: "10px" }}
                              variant="determinate"
                              value={progress}
                              style={{ width: "95%", background: "#e5e6e8" }}
                            />
                          </Grid>
                        </Grid>
                        <ListItemSecondaryAction></ListItemSecondaryAction>
                      </ListItemPdf>
                      <Box>
                        <IconButton
                          style={{ marginLeft: "10px" }}
                          edge="end"
                          aria-label="delete"
                          onClick={handleCancel}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                    </ListItem>
                  )}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        {/* <DialogTitle>Upload file</DialogTitle> */}
        <AppBar
          position="static"
          style={{
            background:
              "radial-gradient(132.65% 173.85% at 70.04% 95.21%, #110D28 26.5%, #A2123D 100%)",
            height: "45px",
            justifyContent: "center",
          }}
        >
          <Toolbar>
            <Box style={{ flexGrow: 1 }}>
              <Typography sx={{ fontFamily: "Outfit" }}>Upload File</Typography>
            </Box>
            <Close onClick={handleClose} style={{ cursor: "pointer" }} />
          </Toolbar>
        </AppBar>{" "}
        <DialogContent>
          <Box sx={{ textAlign: "center", padding: "16px" }}>
            <DropDownUploadBox
              container
              justifyContent={"center"}
              alignItems={"center"}
              direction={"column"}
            >
              <Grid item>
                <PdfIcon height={"66px"} width={"66px"} />
              </Grid>
              <Grid item>
                <Typography>{filename.concat(".pdf")}</Typography>
              </Grid>
            </DropDownUploadBox>
            <Grid container justifyContent={"left"} paddingTop={"10px"}>
              <StyledFormLabel> File Name</StyledFormLabel>
              <StyledTextField
                margin="dense"
                type="text"
                fullWidth
                value={filename}
                onChange={(e) => setFilename(e.target.value)}
                error={!filename}
                helperText={!filename && "Filename required"}
                sx={{ input: { fontFamily: "Outfit" } }}
                // InputProps={{
                //   readOnly: true,
                // }}
              />
              <StyledFormLabel>Description</StyledFormLabel>
              <StyledTextField
                multiline
                margin="dense"
                minRows={3}
                type="text"
                fullWidth
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                sx={{ input: { fontFamily: "Outfit" } }}
              />
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpload} disabled={!filename} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={infoOpen}
        onClose={handleInfoClose}
        PaperProps={{ style: { minWidth: "1000px" } }}
      >
        <DialogTitle sx={{ width: "600px" }}>File info</DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <Box
            sx={{
              padding: "16px",
              // height: '432px', width: '700px', overflow: 'hidden'
            }}
          >
            <Typography sx={{ marginTop: "10px" }}>{infoFile?.name}</Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginTop: "10px" }}
            >
              {infoFile?.description}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ width: "800px" }}>
          <Button onClick={handleInfoClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        sx={{ minWidth: "100px" }}
        open={openAlertFetch}
        autoHideDuration={updatingEmbed ? null : 3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={"bottom" + "right"}
        message={
          updatingEmbed ? (
            <Box display="flex" alignItems="center">
              <CircularProgress size={20} />
              <Typography sx={{ paddingLeft: "20px" }}>
                Wait for PDF processing
              </Typography>
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <CheckCircleOutlineIcon />{" "}
              <Typography sx={{ paddingLeft: "20px" }}>
                PDF Processed successfully
              </Typography>
            </Box>
          )
        }
        action={updatingEmbed ? '' : action}
      />
    </PrivateLayout>
  );
};

export default Home;
