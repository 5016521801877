import React, { useEffect, useState } from "react";
import axios from "axios";
import { GridContainer, GridItem } from "./StyledComponents/Index";
import { Alert, Grid } from "@mui/material";
import { SuccessIcon } from "../assets/svg";
import { StyledFormLabel } from "./Cores/FormCores/style";
import {
  FormHeadingTypography,
  OtpFormTypography,
  OtpSuceessTextFormat,
  OtpTextFormat,
  StyleButton_2,
} from "./Forms/OTP/style";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { StyledField, StyledButton, ErrorGrid } from "./Cores/FormCores/style";
import { Link } from "./Cores/Link/index";
import { apiUrls, help, regularExpressions } from "../Utilities/properties";
import * as Yup from "yup";
import PublicLayout_01 from "../Layout/PublicLayout";
import { set } from "lodash";
import { enqueueSnackbar } from 'notistack';


const EmailRequest: React.FC = () => {
  const [email_address, setEmail_address] = useState("");
  const [message, setMessage] = useState("");
  const [successState, setSuccesState] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [timer, setTimer] = useState<number>(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(
        () => setTimer((prevTimer) => prevTimer - 1),
        1000
      );
      return () => clearInterval(countdown);
    }
  }, [timer]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const validationSchema = Yup.object({
    email_address: Yup.string()
    .email(help.INVALID_EMAIL_ADDRESS)
    .matches(regularExpressions.EMAIL_REGEX, help.INVALID_EMAIL_ADDRESS)
    .required("Email Address " + help.VALUE_REQUIRED),
  });

  const onSubmit = async (values: any) => {
    const { email_address } = values;
    try {
      const response = await axios.post(`${apiUrls.BASEURL}/api/auth/forget`, {
        email_address
      });
      setEmail_address(email_address);
      setMessage(response.data.message);
      setSuccesState(true);
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errMsg = error.response.data.error;
        setErrorMsg(errMsg);
      }else {
        enqueueSnackbar("Error sending email.", {variant:"error"});
      }
    }
    // try {
    //   const logginResponse = await logIn({
    //     email_address: email,
    //     password: password,
    //   });
    //   localStorage.setItem("token", logginResponse.data.access_token);
    //   console.log(logginResponse.data.access_token);
    //   // setSnackbarMessage(success.LOGIN_SUCCESS);
    //   // setSnackbarOpen(true);
    //   enqueueSnackbar(success.LOGIN_SUCCESS, {variant:"success"});
    //   navigate(apiUrls.DASHBOARD);
    // } catch (error: any) {
    //   if (error.response && error.response.data) {
    //     const errMsg = error.response.data.error;
    //     setErrorMsg(errMsg);
    //   }else {
    //     console.error('Error Logging user:', error);
    //     enqueueSnackbar('Failed to login user. Please try again later.', {variant:"error"});
    //   }
    // }
  };
  

  // const handleSubmit = async (e: React.FormEvent) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(`${apiUrls.BASEURL}/api/auth/forget`, {
  //       email,
  //     });
  //     setMessage(response.data.message);
  //     setSuccesState(true);
  //   } catch (error: any) {
  //     setMessage(error.response?.data?.error || "Error sending email.");
  //   }
  // };


  return (
    <PublicLayout_01 verifyState={true}>
      <GridContainer>
        {successState ? (
          <Grid container justifyContent="center" rowSpacing={4}>
            <Grid item>
              <FormHeadingTypography style={{ fontSize: "25px" }}>
                Check email for reset link
              </FormHeadingTypography>
            </Grid>
            <Grid item xs={12} md={12}>
              <OtpTextFormat style={{ fontSize: "14px" }}>
                A email has been sent to {email_address}. Please check your email for the
                reset link.
              </OtpTextFormat>

              <GridItem md={12}>
                <GridContainer justifyContent="center">
                  <Link onClick={() => {}} style={{ cursor: "pointer" }}>
                    Didn't receive an email?{" "}
                  </Link>
                </GridContainer>
              </GridItem>
            </Grid>
          </Grid>
        ) : (
          <Formik
            initialValues={{ email_address: "" }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                <GridContainer>
                  <GridContainer
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    <GridItem xs={12} md={12}>
                      <FormHeadingTypography style={{ fontSize: "25px" }}>
                        Forgot your password
                      </FormHeadingTypography>
                    </GridItem>
                  </GridContainer>
                  <ErrorGrid md={12}>
                    {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
                  </ErrorGrid>
                  <GridItem md={12}>
                    <OtpFormTypography>
                      {" "}
                      {/* Enter OTP code we sent to {maskEmail1(email_address || "")} */}
                    </OtpFormTypography>
                  </GridItem>

                  <GridItem md={12}>
                    <OtpTextFormat>
                      Please enter the email address you'd like your password
                      reset information sent to{" "}
                    </OtpTextFormat>
                  </GridItem>
                  <GridItem md={12} sx={{ marginBottom: "8px" }}>
                    <StyledFormLabel>enter email address</StyledFormLabel>
                  </GridItem>

                  <GridItem md={12} sx={{ height: "80px" }}>
                    <Field
                      name="email_address"
                      type="email"
                      component={StyledField}
                      error={
                        (formik.touched.email_address &&
                          Boolean(formik.errors.email_address)) ||
                        errorMsg
                      }
                      helperText={<ErrorMessage name="email_address" />}
                      onFocus={() => setErrorMsg("")}
                    />
                  </GridItem>

                  <GridItem md={12}>
                    <StyledButton type="submit">
                      Request reset link
                    </StyledButton>
                  </GridItem>
                </GridContainer>
                <GridItem md={12} sx={{ marginBottom: "8px" }}>
                  <Link href={apiUrls.LOG_IN}>Back To Login</Link>
                </GridItem>
              </Form>
            )}
          </Formik>
        )}
      </GridContainer>
    </PublicLayout_01>
  );
};

export default EmailRequest;
