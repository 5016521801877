import axios from "axios";
import { apiUrls } from "./properties";
import { enqueueSnackbar } from "notistack";

const axiosInstance = axios.create({
  baseURL: apiUrls.BASEURL,
});

// Add a request interceptor to include the Authorization header with the access token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // If the response status is 401 and the request is not already retried
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark the request to prevent infinite retry loops

      const refresh_token = localStorage.getItem("refresh_token");
      console.log("refresh_token");
      console.log(refresh_token);
      console.log("refresh_token end");

      if (!refresh_token) {
        console.log("No refresh token, redirect to login");
        return Promise.reject(error);
      }

      try {
        const { data } = await axios.post(
          "http://localhost:5000/api/auth/refresh",
          {},
          {
            headers: {
              Authorization: `Bearer ${refresh_token}`,
            },
          }
        );

        const new_access_token = data.access_token;
        console.log("new_access_token", new_access_token);

        localStorage.setItem("token", new_access_token);

        // Update the Authorization header and retry the original request
        originalRequest.headers["Authorization"] = `Bearer ${new_access_token}`;

        // Retry the original request with the new access token
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // console.error('Token refresh failed:', refreshError);
        enqueueSnackbar("session Expired", { variant: "warning" });
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        window.location.href = "/login";
        // Optionally: redirect to login page or show an error notification
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
