import { styled, Typography } from "@mui/material";
import { StyledButton as Button} from "../../Cores/FormCores";


export const OtpFormTypography = styled(Typography)(({ theme }) => ({
    fontWeight: "400",
    fontSize: "14px",
    fontFamily: "Outfit",
    lineHeight: '14px',
    textAlign: 'left',
    color:'#0059AB',
  }));

  export const OtpTextFormat = styled(Typography)(({ theme }) => ({
    fontSize: "12px",
    fontWeight: "300",
    lineHeight: "12.6px",
    textAlign: "left",
    color: "#1E1E1E",
    fontFamily:'Outfit',
    marginBottom:'30px',
    marginTop:'20px',
  }));

  export const OtpSuceessTextFormat = styled(Typography)(({ theme }) => ({
    fontWeight: "400",
    fontSize: "14px",
    fontFamily: "Outfit",
    lineHeight: '14px',
    textAlign: 'center',
    color:'#0059AB',
    marginBottom:'40px',
  }));

  export const StyleButton_2 = styled(Button)({
    borderRadius: "24px",
    height: "40px",
    border: "1px solid rgba(241, 90, 90, 0.2)",
    boxShadow: "0px 4px 4px 0px rgba(241, 90, 90, 0.12)",
    textTransform: "inherit",
  });

  export const FormHeadingTypography = styled(Typography)(({ theme }) => ({
    fontWeight: "600",
    fontSize: "32px",
    // textAlign:'center'
    // marginBottom:'20px'
  }));

