// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-robot-ask {
    width: auto;
    height: 623px;
    position: absolute;
    bottom: 0;
    right: -30px;
}

.layout-robot-code {
    width: auto;
    height: 623px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.layout-logo {
    width: 208.65px;
    height: auto;
    position: absolute;
    top: 70px;
    left: 70px;
}

.agreementTypography{
    color: #01A4FF;
}

input:-webkit-autofill {
    /* Your custom styles */
    font-family: inherit;
    color: inherit;
    background-color: inherit; /* Example background color */
    border-color: inherit; /* Example border color */
    box-shadow: 0 0 0 100px #fff inset; /* Example box-shadow to override autofill background */
}

/* PhoneInput.css */
/* .phone-input .flag-dropdown {
    background: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
} */

.phone-input .flag {
    border-radius: 50%;
    overflow: hidden;
}

.phone-input .form-control {
    color: #01A4FF; 
}

.phone-input .flag-dropdown .arrow {
    display: none;
}

`, "",{"version":3,"sources":["webpack://./src/css/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,OAAO;AACX;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,uBAAuB;IACvB,oBAAoB;IACpB,cAAc;IACd,yBAAyB,EAAE,6BAA6B;IACxD,qBAAqB,EAAE,yBAAyB;IAChD,kCAAkC,EAAE,uDAAuD;AAC/F;;AAEA,mBAAmB;AACnB;;;;;GAKG;;AAEH;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".layout-robot-ask {\n    width: auto;\n    height: 623px;\n    position: absolute;\n    bottom: 0;\n    right: -30px;\n}\n\n.layout-robot-code {\n    width: auto;\n    height: 623px;\n    position: absolute;\n    bottom: 0;\n    left: 0;\n}\n\n.layout-logo {\n    width: 208.65px;\n    height: auto;\n    position: absolute;\n    top: 70px;\n    left: 70px;\n}\n\n.agreementTypography{\n    color: #01A4FF;\n}\n\ninput:-webkit-autofill {\n    /* Your custom styles */\n    font-family: inherit;\n    color: inherit;\n    background-color: inherit; /* Example background color */\n    border-color: inherit; /* Example border color */\n    box-shadow: 0 0 0 100px #fff inset; /* Example box-shadow to override autofill background */\n}\n\n/* PhoneInput.css */\n/* .phone-input .flag-dropdown {\n    background: none;\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n} */\n\n.phone-input .flag {\n    border-radius: 50%;\n    overflow: hidden;\n}\n\n.phone-input .form-control {\n    color: #01A4FF; \n}\n\n.phone-input .flag-dropdown .arrow {\n    display: none;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
