import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, Grid } from "@mui/material";
import {
  apiUrls,
  help,
  regularExpressions,
  success,
} from "../Utilities/properties";
import { GridContainer, GridItem } from "./StyledComponents/Index";
import {
  FormHeadingTypography,
  StyledField,
  StyledFormLabel,
  StyledButton,
  ErrorGrid,
} from "./Cores/FormCores/style";
import { Box } from "@mui/system";
import { enqueueSnackbar } from 'notistack'
import axios from "axios";
import { SuccessIcon } from "../assets/svg";
import { OtpSuceessTextFormat, StyleButton_2 } from "./Forms/OTP/style";
import PublicLayout_01 from "../Layout/PublicLayout";

export default function ResetPasswordForm() {
  const location = useLocation();
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const [successState, setSuccesState] = useState<boolean>(false);

  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  
  useEffect(()=>{
    if(!token){
        navigate(apiUrls.LOG_IN);
      }
  },[])

  const validationSchema = Yup.object({
    new_password: Yup
      .string()
      .required("Password " + help.VALUE_REQUIRED)
      .matches(regularExpressions.PASSWORD_REGEX, help.PASSWORD_HELP),
    confirm_password: Yup
      .string()
      .oneOf([Yup.ref("new_password"), ""], help.PASSWORD_MISMATCH)
      .required("confirm password " + help.VALUE_REQUIRED),
  });


  const onSubmit = async (values: any) => {
    const { new_password } = values;

    try {
      const response = await axios.post(`${apiUrls.BASEURL}/api/auth/reset-password`, { token, new_password });
      console.log(response.data.message);
      setSuccesState(true);
      enqueueSnackbar(success.RESET_PASSWORD_SUCCESS, {variant:"success"});
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errMsg = error.response.data.error;
        setErrorMsg(errMsg);
      }else {
        enqueueSnackbar('Failed to Reset password. Please try again later.', {variant:"error"});
      }
    }
  };

  return (
    <PublicLayout_01 verifyState={true}>
    <GridContainer>
    {!successState? 
    <Box>
      <Formik
        initialValues={{ new_password: "", confirm_password:'' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            {/* <ValidationErrorHandler setErrorMsg={setErrorMsg} /> */}
            <GridContainer>
              <GridItem md={12}>
                <FormHeadingTypography>Reset Password</FormHeadingTypography>
              </GridItem>
              <ErrorGrid md={12}>
                {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
              </ErrorGrid>
              <GridItem md={12} sx={{ marginBottom: "8px" }}>
                <StyledFormLabel> New password</StyledFormLabel>
              </GridItem>
              <GridItem md={12} sx={{ height: "80px" }}>
                <Field
                  name="new_password"
                  type="password"
                  style={
                    {
                      input:{fontFamily:'Outfit'}
                    }
                  }
                  component={StyledField}
                  error={
                    (formik.touched.new_password && Boolean(formik.errors.new_password)) ||
                    errorMsg
                  }
                  helperText={<ErrorMessage name="new_password" />}
                  onFocus={() => setErrorMsg("")}
                  
                />
              </GridItem>
              <GridItem md={12} sx={{ marginBottom: "8px" }}>
                <GridContainer>
                  <GridItem md={12}>
                    <StyledFormLabel> Confirm password</StyledFormLabel>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem md={12} sx={{ height: "80px" }}>
                <Field
                  name="confirm_password"
                  type="password"
                  component={StyledField}
                  error={
                    (formik.touched.confirm_password &&
                      Boolean(formik.errors.confirm_password)) ||
                    errorMsg
                  }
                  helperText={<ErrorMessage name="confirm_password" />}
                  onFocus={() => setErrorMsg("")}
                />
              </GridItem>

              <GridItem md={12}>
                <StyledButton type="submit">Continue</StyledButton>
              </GridItem>
            </GridContainer>
          </Form>
        )}
      </Formik>
    </Box>:
    <Grid container justifyContent="center" rowSpacing={4}>
    <Grid item sx={{marginTop:'30px'}}>
      <SuccessIcon />
    </Grid>
    <Grid item>
      <FormHeadingTypography>Password changed!</FormHeadingTypography>
    </Grid>
    <Grid item xs={12} md={12}>
      <OtpSuceessTextFormat>Your settings have been saved</OtpSuceessTextFormat>
    </Grid>
    <Grid item xs={5} md={5}>
      <StyleButton_2
        type="button"
        onClick={() => navigate(apiUrls.LOG_IN)}
      >
        Continue
      </StyleButton_2>
    </Grid>
  </Grid>
    }
    </GridContainer>
    </PublicLayout_01>
  );
}
