import React, { useState } from "react";
import { TextField, Box, styled, Typography } from "@mui/material";
import { useField } from "formik";
import logoUploadIcon from "../../../../assets/svg/logoUploadIcon.svg";
import { LogoFileFormat, LogoTypography } from "../style";

const UploadBox = styled(Box)({
  height: "96px",
  border: "2px dashed #01A4FF",
  borderRadius: "12px",
  textAlign: "center",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  transition: "border-color 0.3s",
});

const HiddenInput = styled(TextField)({
  display: "none",
});

interface UploadTextFieldProps {
  name: string;
  setPreview: (file: string | ArrayBuffer | null) => void;
}

const UploadTextField: React.FC<UploadTextFieldProps> = ({ name, setPreview }) => {
  const [field, meta, helpers] = useField(name);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const allowedFileTypes = ['image/png', 'image/jpeg'];
  const [inputClicked, setInputClicked] = useState(false);

  const handleBoxClick = () => {
    setInputClicked(true);
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      handleFileUpload(file);
    }
      if (inputClicked) {
        helpers.setTouched(true);
        helpers.setError('Please select a file.');
      }
    // Reset the clicked state
    setInputClicked(false);
  };

  const handleFileUpload = (file: File) => {
    if (!allowedFileTypes.includes(file.type)) {
      helpers.setError('File type not supported. Please upload a .png or .jpg file.');
      return;
    }
    if(!file){
      helpers.setTouched(true);
      helpers.setError('Please select a file.');
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
    helpers.setValue(file);
    helpers.setTouched(true);

  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      handleFileUpload(file);
    }
  };

  // Detect cancel via timeout
  const triggerCancelCheck = () => {
    setInputClicked(true);
    setTimeout(() => {
      if (inputClicked && !field.value) {
        helpers.setTouched(true);
        helpers.setError('Please select a file.');
        setInputClicked(false);
      }
    }, 300); // Adjust timeout as needed
  };

  return (
    <React.Fragment>
      <UploadBox
        onClick={() => {
          handleBoxClick();
          triggerCancelCheck(); // Start the cancel check on box click
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          borderColor: isDragOver ? "#00c853" : "#01A4FF",
          backgroundColor: isDragOver ? "#e0f7fa" : "transparent",
        }}
      >
        <HiddenInput
          type="file"
          inputRef={fileInputRef}
          onChange={handleFileChange}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
        />
        <img src={logoUploadIcon} height="24px" alt="Upload Icon" style={{ marginTop: '15px' }} />
        <LogoTypography>
          Drop your file here, or <span className="agreementTypography">browse</span>
        </LogoTypography>
        <LogoFileFormat>
          <span style={{ color: "red" }}>*</span>File supported: .jpg & .png
        </LogoFileFormat>
      </UploadBox>
      {meta.touched && meta.error ? (
        <Typography color="error" variant="caption">
          {meta.error}
        </Typography>
      ) : null}
    </React.Fragment>
  );
};

export default UploadTextField;
