import { Button, ButtonProps, IconButton, InputAdornment } from "@mui/material";
import { FieldProps } from "formik";
import { styled, TextField } from "@mui/material";
import { useState } from "react";
import { OffVisibility, OnVisibility } from "./style";

export const StyledField = styled((props: FieldProps & { type?: React.HTMLInputTypeAttribute }) => {
  const { type, field, form, ...otherProps } = props;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...field}
      {...otherProps}
      fullWidth
      autoComplete='off'
      variant="outlined"
      type={showPassword && type === "password" ? "text" : type}
      InputProps={{
        endAdornment: type === "password" && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <OffVisibility /> : <OnVisibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
})({});

export const StyledButton = styled((props: ButtonProps & { type?: React.HTMLInputTypeAttribute }) => {
  const { type, ...otherProps } = props;

  return (
    <Button
      {...otherProps}
      type={type}
      fullWidth
      variant="contained"
      color="primary"
    />
  );
})({});


