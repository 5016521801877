import React, { ReactNode, createContext, useContext, useState } from 'react';
type AvatarContextType = {
  userAvatar: string;
  setUserAvatar: (newAvatar: string) => void;
  userHeaderName: string;
  setUserHeaderName: (newName: string) => void;
  userCompanyName: string;
  setUserCompanyName: (newCompanyName: string) => void;
};
const AvatarContext = createContext<AvatarContextType | undefined>(undefined);
export const useAvatarContext = () => {
  const context = useContext(AvatarContext);
  if (!context) {
    throw new Error('useAvatarContext must be used within an AvatarProvider');
  }
  return context; 
};
type AvatarProviderProps = {
  children: ReactNode;
};
export const AvatarProvider: React.FC<AvatarProviderProps> = ({
  children
}: AvatarProviderProps) => {
  const [userAvatar, setUserAvatar] = useState<string>('');
  const [userHeaderName, setUserHeaderName] = useState<string>('');
  const [userCompanyName, setUserCompanyName] = useState<string>('');
  const value: AvatarContextType = {
    userAvatar,
    setUserAvatar,
    userHeaderName,
    setUserHeaderName,
    userCompanyName,
    setUserCompanyName
  };
  return (
    <AvatarContext.Provider value={value}>{children}</AvatarContext.Provider>
  );
};