import { Alert } from "@mui/material";
import { useState } from "react";
import "../../../../App.css";
import * as yup from "yup";
import MultiStepForm, { FormStep } from "./MultiStepForm";
import { InputField, PhoneField } from "./InputFIeld";
import Avatar from "@mui/material/Avatar";
import { help, regularExpressions } from "../../../../Utilities/properties";
import PublicLayout_01 from "../../../../Layout/PublicLayout";
import { GridContainer, GridItem } from "../../../StyledComponents/Index";
import { ErrorGrid, StyledFormLabel } from "../../../Cores/FormCores/style";
import { FormSubHeadingTypography } from ".././style";
import Stepper from "../../../Stepper";
import UploadTextField from "./Logo";
import {
  Register,
  UserCheck,
  UserCheck2,
} from "../../../../services/auth.services";
import { useNavigate } from "react-router-dom";
import { CountrySelect } from "./CountrySelect";
import { enqueueSnackbar } from 'notistack'


function Multi() {
  const [initialValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    companyName: "",
    companyCode: "",
    companyLogo: null,
    companyRegistrationNo: "",
    country: "",
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);

  const PersonalSchema = yup.object({
    firstName: yup.string().required("First name " + help.VALUE_REQUIRED),
    lastName: yup.string().required("Last name " + help.VALUE_REQUIRED),
    phoneNumber: yup
      .string()
      // .matches(regularExpressions.PHONE_REGEX, help.INVALID_CONTACT_NUMBER)
      .required("Phone number " + help.VALUE_REQUIRED),
    email: yup
      .string()
      .email(help.INVALID_EMAIL_ADDRESS)
      .matches(regularExpressions.EMAIL_REGEX, help.INVALID_EMAIL_ADDRESS)
      .required("Email " + help.VALUE_REQUIRED),
  });

  const CompanySchema = yup.object({
    companyName: yup.string().required("Company Name " + help.VALUE_REQUIRED),
    companyCode: yup
      .string()
      .matches(
        /^[a-z0-9]+$/,
        "Company Code must contain only lowercase and numbers"
      )
      .min(3, "Company Code must be at least 3 characters")
      .max(8, "Company Code must not exceed 8 characters")
      .required("Company Code. " + help.VALUE_REQUIRED),
    companyLogo: yup.mixed().required(),
    // .required("Company Logo " + help.VALUE_REQUIRED),
    companyRegistrationNo: yup
      .string()
      .required("Company Register No. " + help.VALUE_REQUIRED),
    country: yup.string().required("Country " + help.VALUE_REQUIRED),
  });

  const PasswordSchema = yup.object({
    password: yup
      .string()
      .required("Password " + help.VALUE_REQUIRED)
      .matches(regularExpressions.PASSWORD_REGEX, help.PASSWORD_HELP),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), ""], help.PASSWORD_MISMATCH)
      .required("confirm password " + help.VALUE_REQUIRED),
  });

  const onSubmit = async (values: any) => {
    try {
      const formData = new FormData();
      formData.append("first_name", values.firstName);
      formData.append("last_name", values.lastName);
      formData.append("phone_number", values.phoneNumber);
      formData.append("email_address", values.email);
      formData.append("company_name", values.companyName);
      formData.append("company_code", values.companyCode);
      formData.append("company_reg_no", values.companyRegistrationNo);
      formData.append("company_country", values.country);
      formData.append("password", values.password);
      formData.append("logo", values.companyLogo); // Assuming companyLogo is a File object

      const response = await Register(formData); // Assuming Register function handles FormData
      console.log(response.data);
      localStorage.setItem("otp_generated_at", response.data.otp_generated_at);
      localStorage.setItem("expiration_time", response.data.expiration_time);
      localStorage.setItem("emailAddress", values.email);
      navigate("/verify-email", { state: { fromRegister: true } }); // Redirect to login page after successful registration
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errMsg = error.response.data.error;
        // setErrorMsg(errMsg);
        setErrorMsg2(errMsg);
        console.log(error.response.data.error);
        // console.log(errorMsg2);
      } else {
        console.error("Error registering user:", error);
        enqueueSnackbar('Failed to register user. Please try again later.', {variant:"error"});
      }
    }
  };

  const onSubmit_step_one = async (values: any) => {
    try {
      const formData1 = new FormData();
      formData1.append("phone_number", values.phoneNumber);
      formData1.append("email_address", values.email);

      const response = await UserCheck(formData1);
      console.log(response.data);
      return true;
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errMsg = error.response.data.error;

        console.log("response:", error.response.data.error);
        setErrorMsg2(errMsg);
      } else {
        console.error("Error registering user:", error);
        setErrorMsg("Failed to register user. Please try again later.");
      }
      return false;
    }
  };

  const onSubmit_step_two = async (values: any) => {
    try {
      const formData2 = new FormData();
      formData2.append("company_name", values.companyName);
      formData2.append("company_code", values.companyCode);
      formData2.append("company_reg_no", values.companyRegistrationNo);

      const response = await UserCheck2(formData2); // Assuming Register function handles FormData
      console.log(response.data);
      return true;
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errMsg = error.response.data.error;

        console.log("response:", error.response.data.error);
        setErrorMsg2(errMsg);
      } else {
        console.error("Error registering user:", error);
        setErrorMsg("Failed to register user. Please try again later.");
      }
      return false;
    }
  };

  //   const MAX_FILE_SIZE = 102400; // 100KB
  //   const validFileExtensions = { image: ['gif', 'png', 'jpeg', 'svg', 'webp'] };

  //   function isValidFileType(fileName: string, fileType: keyof typeof validFileExtensions) {
  //     return fileName && validFileExtensions[fileType].includes(fileName.split('.').pop()!.toLowerCase());
  //   }

  //   const thirdSchema = yup.object().shape({
  //     image: yup
  //       .mixed()
  //       .required("Image is required")
  //     //   .test("fileSize", "Max allowed size is 100KB", (value: any) => value && value.size <= MAX_FILE_SIZE)
  //     //   .test("fileType", "Unsupported file type", (value: any) => value && isValidFileType(value.name, "image"))
  //   });
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsg2, setErrorMsg2] = useState<{ [key: string]: string }>({});

  return (
    <PublicLayout_01 verifyState={false}>
      <MultiStepForm
        initialValues={initialValues}
        onSubmit={() => {
          console.log("form submitted");
        }}
      >
        <FormStep
          stepName="person"
          onSubmit={async (values: any) => {
            try {
              const success = await onSubmit_step_one(values);
              if (!success) {
                return false;
              }
              return true;
            } catch (error) {
              console.error("Error:", error);
              return false; 
            }
          }}
          validationSchema={PersonalSchema}
        >
          <GridContainer sx={{ justifyContent: "center" }}>
            {/* <ErrorGrid md={12}>
              {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
              {Object.keys(errorMsg2).map((key) => (
                <Alert key={key} severity="error">
                  {errorMsg2[key]}
                </Alert>
              ))}
            </ErrorGrid> */}
            <GridItem md={12}>
              <GridContainer
                sx={{
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                {/* <CustomCircularProgress></CustomCircularProgress> */}
                <GridItem xs={10} md={9}>
                  <FormSubHeadingTypography>
                    Personal Information
                  </FormSubHeadingTypography>
                </GridItem>
                <GridItem xs={2} md={3}>
                  <GridContainer
                    sx={{ alignItems: "center", justifyContent: "right" }}
                  >
                    <Stepper currentStep={1} totalSteps={4} gap={true} />
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={12} sx={{ marginBottom: "8px" }}>
              <StyledFormLabel> First name</StyledFormLabel>
            </GridItem>
            <GridItem md={12} sx={{ height: "78px" }}>
              <InputField name="firstName" type="text" />
            </GridItem>
            <GridItem md={12} sx={{ marginBottom: "8px" }}>
              <StyledFormLabel> Last name</StyledFormLabel>
            </GridItem>
            <GridItem md={12} sx={{ height: "78px" }}>
              <InputField name="lastName" type="text" />
            </GridItem>
            <GridItem md={12} sx={{ marginBottom: "8px" }}>
              <StyledFormLabel> Phone number</StyledFormLabel>
            </GridItem>
            <GridItem md={12} sx={{ height: "78px" }}>
              <PhoneField
                name="phoneNumber"
                error2={errorMsg2.phone_number}
                onFocus={() =>
                  setErrorMsg2((previous) => ({
                    ...previous,
                    phone_number: "",
                  }))
                }
              />

            </GridItem>
            <GridItem md={12} sx={{ marginBottom: "8px" }}>
              <StyledFormLabel> Email</StyledFormLabel>
            </GridItem>
            <GridItem md={12} sx={{ height: "78px" }}>
              <InputField
                name="email"
                type="email"
                error2={errorMsg2.email_address}
                onFocus={() =>
                  setErrorMsg2((previous) => ({
                    ...previous,
                    email_address: "",
                  }))
                }
              />
            </GridItem>
          </GridContainer>
        </FormStep>

        <FormStep
          stepName="second"
          onSubmit={async (values: any) => {
            try {
              const success = await onSubmit_step_two(values);
              if (!success) {
                return false;
              }
              return true; 
            } catch (error) {
              console.error("Error:", error);
              return false;
            }
          }}
          validationSchema={CompanySchema}
        >
          <GridContainer sx={{ justifyContent: "center" }}>
            {/* <ErrorGrid md={12}>
              {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
            </ErrorGrid> */}
            <GridItem md={12}>
              <GridContainer
                sx={{
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                {/* <CustomCircularProgress></CustomCircularProgress> */}
                <GridItem xs={10} md={9}>
                  <FormSubHeadingTypography>
                    Company Information
                  </FormSubHeadingTypography>
                </GridItem>
                <GridItem xs={2} md={3}>
                  <GridContainer
                    sx={{ alignItems: "center", justifyContent: "right" }}
                  >
                    <Stepper currentStep={2} totalSteps={4} gap={true} />
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={12} sx={{ marginBottom: "8px" }}>
              <StyledFormLabel> Company Name</StyledFormLabel>
            </GridItem>
            <GridItem md={12} sx={{ height: "78px" }}>
              <InputField
                name="companyName"
                type="text"
                error2={errorMsg2.company_name}
                onFocus={() =>
                  setErrorMsg2((previous) => ({
                    ...previous,
                    company_name: "",
                  }))
                }
              />
            </GridItem>
            <GridItem md={12} sx={{ marginBottom: "8px" }}>
              <StyledFormLabel> Company Code</StyledFormLabel>
            </GridItem>
            <GridItem md={12} sx={{ height: "78px" }}>
              <InputField
                name="companyCode"
                type="text"
                error2={errorMsg2.company_code}
                onFocus={() =>
                  setErrorMsg2((previous) => ({
                    ...previous,
                    company_code: "",
                  }))
                }
              />
            </GridItem>
            <GridItem md={12} sx={{ marginBottom: "8px" }}>
              <StyledFormLabel> Company Logo</StyledFormLabel>
            </GridItem>
            {/* <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid xs={4.5} md={4.5} >
                  <Avatar src="/broken-image.jpg" sx={{height:'96px', width:'96px',border: '4px solid #FFFFFF',boxShadow: '0px 2px 5px 0px #0000001F'}}/>
              </Grid>
            <Grid xs={7.5} md={7.5}>
              <UploadTextField/>
            </Grid>
            </Grid> */}
            <GridContainer mb={"10px"}>
              <GridItem xs={4.5} md={4.5} sx={{ height: "104px" }}>
                <Avatar
                  src={preview ? preview.toString() : "/broken-image.jpg"}
                  sx={{
                    height: "96px",
                    width: "96px",
                    border: "4px solid #FFFFFF",
                    boxShadow: "0px 2px 5px 0px #0000001F",
                  }}
                />
              </GridItem>
              <GridItem xs={7.5} md={7.5} sx={{ height: "104px" }}>
                <UploadTextField name="companyLogo" setPreview={setPreview} />
                {/* <InputField name="companyLogo" type="file" /> */}
              </GridItem>
            </GridContainer>
            <GridItem md={12} sx={{ marginBottom: "8px" }}>
              <StyledFormLabel> Company Reg No.</StyledFormLabel>
            </GridItem>
            <GridItem md={12} sx={{ height: "78px" }}>
              {/* <InputField name="companyRegistrationNo" type="text" /> */}
              <InputField
                name="companyRegistrationNo"
                type="text"
                error2={errorMsg2.company_reg_no}
                onFocus={() =>
                  setErrorMsg2((previous) => ({
                    ...previous,
                    company_reg_no: "",
                  }))
                }
              />
            </GridItem>
            <GridItem md={12} sx={{ marginBottom: "8px" }}>
              <StyledFormLabel> Country</StyledFormLabel>
            </GridItem>
            <GridItem md={12} sx={{ height: "78px" }}>
              {/* <InputField name="country" type="text" /> */}
              <CountrySelect name="country" />
            </GridItem>
          </GridContainer>
        </FormStep>

        <FormStep
          stepName="third"
          onSubmit={onSubmit}
          validationSchema={PasswordSchema}
        >
          <GridContainer sx={{ justifyContent: "center" }}>
            <ErrorGrid md={12}>
              {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
            </ErrorGrid>
            <GridItem md={12}>
              <GridContainer
                sx={{
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                {/* <CustomCircularProgress></CustomCircularProgress> */}
                <GridItem xs={10} md={9}>
                  <FormSubHeadingTypography>
                    Set Password
                  </FormSubHeadingTypography>
                </GridItem>
                <GridItem xs={2} md={3}>
                  <GridContainer
                    sx={{ alignItems: "center", justifyContent: "right" }}
                  >
                    <Stepper currentStep={3} totalSteps={4} gap={true} />
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={12} sx={{ marginBottom: "8px" }}>
              <StyledFormLabel> Password</StyledFormLabel>
            </GridItem>
            <GridItem md={12}>
              <InputField name="password" type="password" />
            </GridItem>

            <GridItem md={12} sx={{ marginBottom: "8px" }}>
              <StyledFormLabel> Confirm password</StyledFormLabel>
            </GridItem>
            <GridItem md={12} sx={{ height: "78px" }}>
              <InputField name="confirmPassword" type="password" />
            </GridItem>
          </GridContainer>
        </FormStep>
      </MultiStepForm>
    </PublicLayout_01>
  );
}

export default Multi;
