import React from 'react';
import styled from 'styled-components';

interface StepperProps {
  totalSteps: number;
  currentStep: number;
  gap: boolean;
}

const StepperContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  position: relative;
`;

const Circle = styled.svg`
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
`;

const CircleBg = styled.circle`
  fill: #FFFFFF;
  stroke: #01A4FF40;
  stroke-width: 3;
`;

const CircleSegment = styled.circle<{ strokeDasharray: string; gap: boolean }>`
  fill: none;
  stroke: #01A4FF;
  stroke-width: 3;
  stroke-dasharray: ${({ strokeDasharray }) => strokeDasharray};
  stroke-linecap: ${({ gap }) => (gap ? 'round' : 'butt')};
  transition: stroke-dasharray 0.5s ease;
`;

const StepNumber = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: bold;
  color: #01A4FF
  ;
`;

const Stepper: React.FC<StepperProps> = ({ totalSteps, currentStep, gap }) => {
  const radius = 16;
  const circumference = 2 * Math.PI * radius;
  const segmentLength = circumference / totalSteps - (gap ? 5 : 0); // Adjust segment length based on gap
  const filledLength = segmentLength * currentStep + (gap ? currentStep * 5 : 0);
  const remainingLength = circumference - filledLength;

  const strokeDasharray = `${filledLength} ${remainingLength}`;

  return (
    <StepperContainer>
      <Circle>
        <CircleBg cx="50%" cy="50%" r={radius} />
        <CircleSegment cx="50%" cy="50%" r={radius} strokeDasharray={strokeDasharray} gap={gap} />
      </Circle>
      <StepNumber>{currentStep}</StepNumber>
    </StepperContainer>
  );
};

export default Stepper;
